/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createRef,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Tooltip } from '@mui/material'
import { Document, Page, pdfjs } from 'react-pdf'
import { ZoomInIcon, Minus, Plus, ZoomOutIcon } from 'lucide-react'
import debounce from 'lodash/debounce'
import cn from 'classnames'
import { FixedSizeList as List } from 'react-window'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

const Item = ({
  index,
  style,
  pageRefs,
  docWidth,
  zoom,
}: {
  index: number
  style: any
  pageRefs: any
  docWidth: number
  zoom: number
}) => (
  <div key={index} style={style} className="mb-2 w-full justify-center flex">
    <Page
      ref={pageRefs[index]}
      key={`page_${index + 1}`}
      pageNumber={index + 1}
      renderAnnotationLayer={true}
      width={docWidth}
      scale={zoom}
      loading={''}
    />
  </div>
)

const PdfViewer = ({ url }: { url: string }) => {
  const [numPages, setNumPages] = useState<any>('')
  const [activePage, setActivePage] = useState(1)
  const [pageRefs, setPageRefs] = useState<any>([])
  const listRef = createRef()
  const documentRef = useRef<HTMLDivElement>(null)
  const [docWidth, setDocWidth] = useState<number>(0)
  const [docHeight, setDocHeight] = useState<number>(0)
  const [zoom, setZoom] = useState<number>(1)
  const zoomRef = useRef(zoom)

  const debouncedSetZoom = debounce((zoomLevel) => {
    setZoom(zoomLevel)
  }, 100)

  const zoomIn = () => {
    if (zoomRef.current < 1.5) {
      zoomRef.current += 0.1
      debouncedSetZoom(zoomRef.current)
    }
  }

  const zoomOut = () => {
    if (zoomRef.current > 0.5) {
      zoomRef.current -= 0.1
      debouncedSetZoom(zoomRef.current)
    }
  }

  const fitToWidth = () => {
    zoomRef.current = 1
    debouncedSetZoom(zoomRef.current)
  }

  const goToPage = (pageNumber: number) => {
    ;(listRef.current as any).scrollToItem(pageNumber - 1, 'center')
    setActivePage(pageNumber)
  }

  const handlePageInputChange = (e: any) => {
    const page = parseInt(e.target.value, 10)
    if (!isNaN(page) && page >= 1 && page <= numPages) {
      goToPage(page)
    }
  }

  const documentOptions = useMemo(
    () => ({
      cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
      cMapPacked: true,
    }),
    []
  )

  const onDocumentLoadSuccess = useCallback(
    ({ numPages: nextNumPages }: any) => {
      setNumPages(nextNumPages)
      const refs = Array.from({ length: nextNumPages }, () => createRef())
      setPageRefs(refs)
      const rect = documentRef.current?.getBoundingClientRect()

      if (rect) {
        setDocWidth(rect.width)
        setDocHeight(rect.height)
      }
    },
    []
  )

  const handleScroll = debounce(() => {
    const list = listRef.current as any
    const scrollY = list?.state.scrollOffset
    const pageHeight = (docWidth * zoom) / 1.27
    const currentPage = scrollY ? Math.floor(scrollY / pageHeight) + 1 : 1
    setActivePage(currentPage)
  }, 0)

  const tooltipTitle = zoom >= 1.5 ? 'Reset zoom' : 'Fit to width'
  const Icon = zoom >= 1.5 ? ZoomOutIcon : ZoomInIcon

  const memoizedItem = useCallback(
    ({ index, style }: { index: number; style: any }) => (
      <Item
        index={index}
        style={style}
        pageRefs={pageRefs}
        docWidth={docWidth}
        zoom={zoom}
      />
    ),
    [pageRefs, docWidth, zoom]
  )

  return (
    <div
      data-cy="pdf-preview"
      className="h-full w-full flex flex-col relative justify-center items-center"
    >
      <div className="h-12 w-fit flex gap-4 justify-center items-center bg-black/70 rounded-full scale-[0.7] lg:scale-90 px-4 my-2 fixed bottom-12 lg:bottom-4 z-10">
        <div className="text-white w-fit">
          Page{' '}
          <input
            value={activePage}
            onChange={handlePageInputChange}
            className="bg-[#191B1C] text-center w-[35px]"
          />{' '}
          / {numPages}
        </div>
        <div className="text-white flex gap-4 items-center">
          <Tooltip title="Zoom out">
            <button
              onClick={zoomOut}
              disabled={zoom <= 0.5}
              className={cn({
                'text-gray-400': zoom <= 0.5,
              })}
            >
              <Minus fontSize={16} />
            </button>
          </Tooltip>

          <span>
            <Tooltip title={tooltipTitle}>
              <button onClick={fitToWidth}>
                <Icon fontSize={16} />
              </button>
            </Tooltip>
          </span>
          <Tooltip title="Zoom in">
            <button
              onClick={zoomIn}
              disabled={zoom >= 1.5}
              className={cn({
                'text-gray-400': zoom >= 1.5,
              })}
            >
              <Plus fontSize={16} />
            </button>
          </Tooltip>
        </div>
      </div>
      <div
        className="w-full flex-grow overflow-scroll no-scrollbar flex-col h-full justify-center items-center relative"
        ref={documentRef}
      >
        <div className="max-w-full flex justify-center h-full">
          <Document
            options={documentOptions}
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <div className="text-white border flex justify-center">
                Loading PDF ...
              </div>
            }
          >
            <List
              height={docHeight}
              itemCount={numPages}
              itemSize={(docWidth * zoom) / 1.27}
              width={docWidth}
              ref={listRef as any}
              onScroll={handleScroll}
            >
              {memoizedItem}
            </List>
          </Document>
        </div>
      </div>
    </div>
  )
}

export default memo(PdfViewer)
