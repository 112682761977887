import { PrivyClientConfig } from '@privy-io/react-auth'
import { ENV, APP_ENV } from '../constants'
import { defaultNetwork, gnosisChain, sepoliaChain } from './network-config'
import { getChainsByDAppEnvironment } from './wagmi-config'

const WARPCAST_FRAME_MAP = {
  [ENV.DEV]: 'https://heartframe-poc.vercel.app/portal/',
  [ENV.PROD]: 'https://frame.fileverse.io/portal/',
  [ENV.STAG]: 'https://staging-heart-frame.vercel.app/portal/',
}

const PRIVY_APP_ID_MAP = {
  [ENV.DEV]: 'clt5lu2r500d73186bze1oq1o',
  [ENV.PROD]: 'clwexuslg05n5uqkwvoyxlmng',
  [ENV.STAG]: 'clswpvvu600a316l5hkfsli8e',
}

const SUBGRAPH_URL_MAP = {
  [ENV.DEV]:
    'https://subgraph.satsuma-prod.com/3c76b4de84df/b3091e3f59457e838d81b3b921d467ff2cc04893951558e2b3e5db9a1caa7520/fileversePortalDev/api',
  [ENV.STAG]:
    'https://subgraph.satsuma-prod.com/3c76b4de84df/b3091e3f59457e838d81b3b921d467ff2cc04893951558e2b3e5db9a1caa7520/fileversePortalProd/api',
  [ENV.PROD]:
    'https://subgraph.satsuma-prod.com/3c76b4de84df/b3091e3f59457e838d81b3b921d467ff2cc04893951558e2b3e5db9a1caa7520/fileversePortalProd/api',
}

export const WC_PROJECT_ID = '90aa999fbdb4f8e3b95784b4824fe1c7'

export const SUBGRAPH_URL = SUBGRAPH_URL_MAP[APP_ENV]

export const FRAME_URL = WARPCAST_FRAME_MAP[APP_ENV]

export const PRIVIY_APP_ID = PRIVY_APP_ID_MAP[APP_ENV]

export const PRIVY_CONFIG: PrivyClientConfig = {
  loginMethods: ['email'],
  defaultChain:
    defaultNetwork.chainId === 11155111 ? sepoliaChain : gnosisChain,
  supportedChains: getChainsByDAppEnvironment(),
  walletConnectCloudProjectId: WC_PROJECT_ID,
  embeddedWallets: {
    createOnLogin: 'users-without-wallets',
  },
  appearance: {
    theme: '#FFFFFF',
    showWalletLoginFirst: false,
    accentColor: '#ffdf0d',
    walletList: ['metamask', 'coinbase_wallet', 'wallet_connect'],
  },
}

export const WC_CONNECTOR_TYPE = 'wallet_connect_v2'
export const METAMASK_WALLET_CLIENT_TYPE = 'metamask'

export const WALLET_RPC_METHOD_MAP = {
  ADD_CHAIN: 'wallet_addEthereumChain',
}
